<template lang="pug">
include ../../../configs/template
v-card.p-2
  v-card-text.row
    div.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +select-validation('body.type_document', 'mappingAccrualTypeDoc', 'typeDoc', '"value"', '["required"]' )
    div.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +select-validation('body.type_of_form', 'formTypeList', 'form', 'lang', '["required"]')(item-value="value")
    div.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +date-picker-validation('body.date_start', 'dateEffective', 'dateStartObject', '["required", "maxValue", "minValue"]')
    div.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('body.full_price','coming','["required", "minValue"]')(type="number")
    div.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('body.to_sqc','toSQC','["required", "minValue"]')(type="number")
    div.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('body.to_qd','toQD','["required", "minValue"]')(type="number")
    div.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('body.to_td','toTD','["required", "minValue"]')(type="number")
    div.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('body.to_cec','toCEC','["required", "minValue"]')(type="number")
    div.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('body.to_sc','toSC','["required", "minValue"]')(type="number")
    div.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('body.to_crewing','toCrewingManager','["required", "minValue"]')(type="number")
    div.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('body.to_medical','toMedical','["required", "minValue"]')(type="number")
    div.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('body.to_mrc','toMRC','["required", "minValue"]')(type="number")
    div.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('body.to_portal','toPortal','["required", "minValue"]')(type="number")
  v-card-actions
    div.w-100.text-center
      v-btn(color="success" :loading="isLoading" @click="saveDocument") {{ $t('save') }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { dateFormat } from '@/mixins/main'
import { DocumentsOfflinePrice } from '@/configs/validations/index'
import { SUCCESS_CODE } from '@/configs/constants'

const initBody = () => ({
  to_sqc: null,
  to_qd: null,
  to_td: null,
  to_sc: null,
  to_crewing: null,
  to_medical: null,
  to_cec: null,
  to_portal: null,
  to_mrc: null,
  full_price: null,
  date_start: null,
  type_of_form: null,
  type_document: null
})

export default {
  name: 'BackOfficePriceETIOfflineAdd',
  props: {
    getDocuments: { type: Function, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    typeCourse: { type: String, default: 'Offline' }
  },
  data () {
    return {
      dateTomorrow: null,
      isLoading: false,
      body: initBody()
    }
  },
  computed: {
    ...mapState({
      lang: state => state.main.lang,
      // mapping documents
      mappingAccrualTypeDoc: state => state.directory.allAccrualTypeDoc
    }),
    dateStartObject () {
      return this.body.date_start ? new Date(this.body.date_start) : null
    },
    formTypeList () {
      return [
        {
          id: 1,
          ua: 'Ф1 (грн.)',
          en: 'F1 (uah.)',
          value: 'First'
        },
        {
          id: 2,
          ua: this.body?.type_document === 9 ? 'Ф2 (%)' : 'Ф2 ($)',
          en: this.body?.type_document === 9 ? 'F2 (%)' : 'F2 ($)',
          value: 'Second'
        }
      ]
    }
  },
  validations () {
    return DocumentsOfflinePrice(this)
  },
  mounted () {
    // Get tomorrow's date
    let tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    this.dateTomorrow = dateFormat(tomorrow)
    if (this.row) {
      Object.keys(this.body).forEach(key => (this.body[key] = this.row[key]))
    }
  },

  methods: {
    ...mapActions(['postDocumentPriceOffline', 'getListOfflinePriceOfDocuments', 'patchDocumentPriceOffline']),
    /** Save document */
    async saveDocument () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      Object.keys(this.body).forEach(key => {
        if (!['date_start', this.row?.id ? 'date_start' : 'type_of_form', 'type_document'].includes(key)) this.body[key] = parseFloat(this.body[key])
      })
      const response = this.row?.id
        ? await this[`patchDocumentPrice${this.$route.params.typePrice}`]({ body: this.body, id: this.row.id })
        : await this[`postDocumentPrice${this.$route.params.typePrice}`]({ body: this.body })
      this.isLoading = false
      if (SUCCESS_CODE.includes(response.code)) {
        !this.row && (this.$parent.isViewAddSlot = false)
        this.body = initBody()
        this.$v.$reset()
      }
    }
  }
}

</script>
