import { maxValue, minValue, required, requiredIf } from 'vuelidate/lib/validators'
import { dateFormat } from '@/mixins/main'

const checkSumRatio = (items, value) => {
  let sum = 0
  items.map(val => {
    val.error = false
    sum += (val.ratio === '' || val.ratio === null) ? 0 : parseInt(val.ratio)
  })
  let numbers = items.map(el => el.ratio)

  return Math.max.apply(null, numbers) === value && sum > 100
  // if (sum > 100) return false
  // else return true
}

let tomorrow = new Date()
tomorrow.setDate(new Date().getDate() + 1)
const dateTomorrow = dateFormat(tomorrow)

export const coefficientETI = (context) => (
  {
    body: {
      percent_of_eti: { required, minValue: minValue(0), maxValue: maxValue(100) },
      percent_of_profit: { required, minValue: minValue(0), maxValue: maxValue(100) }
    },
    dateIssueObject: {
      required,
      minValue: minValue(new Date(dateTomorrow)),
      maxValue: maxValue(new Date('2200-01-01'))
    }

  }
)

export const DealingETI = (context) => (
  {
    items: {
      $each: {
        ratio: {
          maxValue: maxValue(100),
          minValue: minValue(0),
          summRatio: (value) => {
            return !(checkSumRatio(context.items, value))
          }
        }
      }
    }
  }
)

export const DocumentsOfflinePrice = (context) => ({
  body: {
    type_of_form: { required: requiredIf(() => !context.$route.params.documentID) },
    type_document: { required: requiredIf(() => !context.$route.params.documentID) },
    full_price: { required, minValue: minValue(0) },
    to_sqc: { required, minValue: minValue(0) },
    to_qd: { required, minValue: minValue(0) },
    to_td: { required, minValue: minValue(0) },
    to_sc: { required, minValue: minValue(0) },
    to_crewing: { required, minValue: minValue(0) },
    to_medical: { required, minValue: minValue(0) },
    to_cec: { required, minValue: minValue(0) },
    to_portal: { required, minValue: minValue(0) },
    to_mrc: { required, minValue: minValue(0) }
  },
  dateStartObject: {
    required,
    minValue: minValue(new Date(dateTomorrow)),
    maxValue: maxValue(new Date('2200-01-01'))
  }
})
